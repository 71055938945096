import { createRouter, createWebHistory } from 'vue-router'
import privacyPage from '../components/privacyPage.vue'
import serviceUse from '../components/serviceUse.vue'
import HomePage from '../components/HomePage.vue'

const routes = [
    {
        path: '/',
        redirect: '/home' // 将根路径重定向到 '/home'
    },
    {
        path: '/home',
        name: 'home',
        component: HomePage
    },
    {
        path: '/privacy-policy',
        name: 'privacyPage',
        component: privacyPage
    },
    {
        path: '/service-use',
        name: 'serviceUse',
        component: serviceUse
    },
    {
        path: '/:catchAll(.*)', // 通配符'*'匹配所有未知的路由
        redirect: '/home' // 将未知的路由重定向到 '/home'
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})


router.beforeEach((to, from, next) => {
    next();
});

export default router
